import { Component, inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  public active = 1;
  private sessionService = inject(SessionService);

  constructor(private formBuilder: UntypedFormBuilder, private router: Router) {
    this.createLoginForm();
    this.createRegisterForm();
  }

  owlcarousel = [
    {
      title: 'Bem vindo ao Fich ERP',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: 'Bem vindo a Fich ERP',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: 'Bem vindo a Fich ERP',
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
  ];
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true,
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', Validators.required],
      confirmPassword: [''],
    });
  }

  ngOnInit() {}

  onSubmitLogin() {
    if (this.loginForm.valid) {
      // Aqui você pode enviar os dados do formulário para a autenticação ou realizar outras ações necessárias
      const formData = this.loginForm.value;
      console.log('Dados do formulário:', formData);

      this.sessionService
        .login(formData.userName, formData.password)
        .subscribe((data) => {
          console.log(data);
          localStorage.setItem('token', data.token);
          this.router.navigate(['/dashboard/default']);
        });
    }
  }

  onSubmitRegister() {
    if (this.registerForm.valid) {
      // Aqui você pode enviar os dados do formulário para a autenticação ou realizar outras ações necessárias
      const formData = this.registerForm.value;
      console.log('Dados do formulário:', formData);

      //todo fazer cadastro

      this.router.navigate(['/dashboard/default']);
    }
  }
}
