<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <div class="card bg-primary">
            <div class="img-icon">
              <img src="assets/images/logo.png" />
            </div>
            <div class="single-item">
              <owl-carousel-o [options]="owlcarouselOptions">
                <ng-container *ngFor="let data of owlcarousel">
                  <ng-template carouselSlide class="item">
                    <div>
                      <h3>{{ data.title }}</h3>
                      <p>{{ data.desc }}</p>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        </div>
        <div class="col-md-7 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-body">
              <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>Entrar</a>
                  <ng-template ngbNavContent>
                    <div
                      class="tab-pane fade active show"
                      id="account"
                      role="tabpanel"
                      aria-labelledby="account-tab"
                    >
                      <form
                        [formGroup]="loginForm"
                        class="form-horizontal auth-form"
                        novalidate=""
                        (ngSubmit)="onSubmitLogin()"
                      >
                        <div class="form-group">
                          <input
                            required="true"
                            formControlName="userName"
                            type="email"
                            class="form-control"
                            placeholder="Usuário"
                            id="exampleInputEmail1"
                          />
                        </div>
                        <div class="form-group">
                          <input
                            required="true"
                            formControlName="password"
                            type="password"
                            class="form-control"
                            placeholder="Senha"
                          />
                        </div>
                        <div class="form-terms">
                          <div
                            class="custom-control custom-checkbox d-flex align-items-center flex-wrap"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input me-2"
                              id="customControlAutosizing"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlAutosizing"
                              >Lembrar-me</label
                            >
                            <a
                              href="javascript:void(0)"
                              class="btn btn-default forgot-pass ms-auto"
                              >Equeci minha senha</a
                            >
                          </div>
                        </div>
                        <div class="form-button">
                          <button
                            class="btn btn-primary"
                            type="submit"
                            [disabled]="loginForm.invalid"
                          >
                            Entrar
                          </button>
                        </div>
                        <div class="form-footer">
                          <span>Entre com as redes sociais</span>
                          <ul class="social">
                            <li>
                              <a
                                class="icon-facebook"
                                href="javascript:void(0)"
                              ></a>
                            </li>
                            <li>
                              <a
                                class="icon-twitter"
                                href="javascript:void(0)"
                              ></a>
                            </li>
                            <li>
                              <a
                                class="icon-instagram"
                                href="javascript:void(0)"
                              ></a>
                            </li>
                            <li>
                              <a
                                class="icon-pinterest"
                                href="javascript:void(0)"
                              ></a>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Registrar</a>
                  <ng-template ngbNavContent>
                    <form
                      [formGroup]="registerForm"
                      class="form-horizontal auth-form"
                      novalidate
                      (ngSubmit)="onSubmitRegister()"
                    >
                      <div class="form-group">
                        <input
                          required=""
                          formControlName="userName"
                          type="email"
                          class="form-control"
                          placeholder="Usuário"
                          id="exampleInputEmail12"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          required=""
                          formControlName="password"
                          type="password"
                          class="form-control"
                          placeholder="Senha"
                        />
                      </div>
                      <div class="form-terms">
                        <div class="custom-control custom-checkbox d-flex">
                          <input
                            type="checkbox"
                            class="custom-control-input mt-1 me-2"
                            id="customControlAutosizing1"
                          />
                          <label
                            class="custom-control-label"
                            for="customControlAutosizing1"
                          >
                            <span
                              >Eu li e concordo com o termos e condições
                              <a href="javascript:void(0)"
                                >Termos e condições</a
                              ></span
                            ></label
                          >
                        </div>
                      </div>
                      <div class="form-button">
                        <button
                          class="btn btn-primary"
                          type="submit"
                          [disabled]="registerForm.invalid"
                        >
                          Registrar
                        </button>
                      </div>
                      <div class="form-footer">
                        <span>Registre-se com as redes sociais</span>
                        <ul class="social">
                          <li>
                            <a href="javascript:void(0)"
                              ><i class="icon-facebook"></i
                            ></a>
                          </li>
                          <li>
                            <a href="javascript:void(0)"
                              ><i class="icon-twitter"></i
                            ></a>
                          </li>
                          <li>
                            <a href="javascript:void(0)"
                              ><i class="icon-instagram"></i
                            ></a>
                          </li>
                          <li>
                            <a href="javascript:void(0)"
                              ><i class="icon-pinterest"></i
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
